import React from "react";
import { Link } from "gatsby";
import Image from "gatsby-image";
import { kebabCase, getGatsbyImage } from "helpers";
import { BsArrowRight } from "react-icons/bs";

/* Sponsor Item
============================================================================= */

const SponsorItem = ({ sponsor }) => {
	// Config

	// Render
	return (
		<div className="grid md:grid-cols-4 grid-cols-1 gap-8">
			<Link to="/sponsors/rode" className="md:col-span-1">
				<Image
					fluid={getGatsbyImage(sponsor.image)}
					alt={sponsor.image.alt}
					className="square-image shadow-lg rounded-md bg-black"
					style={{
						overflow: "hidden",
					}}
					placeholderStyle={{
						filter: "blur(50px)",
					}}
					imgStyle={{
						objectFit: "contain",
						objectPosition: "50% 50%",
					}}
				/>
			</Link>
			<div className="md:col-span-3">
				<Link
					to="/sponsors/rode"
					className="font-body font-semibold text-3xl pb-2 hover:text-gold"
				>
					{sponsor.name}
				</Link>
				<p className="font-body pb-8 leading-relaxed">
					{sponsor.description}
				</p>
				<a
					href={sponsor.website}
					target="_blank"
					rel="noopener noreferrer"
					aria-label="Website"
					className="text-black font-body text-lg flex flex-row items-center"
				>
					Website{" "}
					<span className="pl-2">
						<BsArrowRight size={20} />
					</span>
				</a>
			</div>
		</div>
	);
};

/* Export
============================================================================= */

export default SponsorItem;
