import React from "react";
import SponsorItem from "./sponsorItem";
import { SectionHeading } from "components/layout";
import { List } from "components/layout";

/* Sponsor List
============================================================================= */

const SponsorList = ({ sponsors, title = "Litmus Media" }) => {
	// Config

	// Render Sponsors
	const renderSponsors = () => {
		return sponsors.map((sponsor) => {
			return <SponsorItem sponsor={sponsor} key={sponsor.name} />;
		});
	};

	// Render
	return (
		<List
			ariaLabel="Sponsors"
			title="Sponsors"
			grid="grid-cols-1 gap-12"
			className="padding-bottom"
		>
			{renderSponsors()}
		</List>
	);
};

/* Export
============================================================================= */

export default SponsorList;
