import React, { useEffect } from "react";
import { Link, graphql, useStaticQuery } from "gatsby";
import { SEO } from "components";
import Image from "gatsby-image";
import BlockContent from "@sanity/block-content-to-react";
import { SponsorItem } from "components/sponsors";
import { Section } from "components/layout";
import useMenu from "state/useMenu";
import { BsArrowRight } from "react-icons/bs";

/* Sponsor Template
============================================================================= */

const SponsorTemplate = ({ pageContext }) => {
	// Config
	const { sponsor } = pageContext;
	const { setAd } = useMenu();
	const data = useStaticQuery(graphql`
		query SponsorQuery {
			rode: file(relativePath: { eq: "rode.jpg" }) {
				childImageSharp {
					fluid(maxWidth: 1000) {
						...GatsbyImageSharpFluid_noBase64
					}
				}
			}
		}
	`);

	useEffect(() => {
		setAd();
	}, []);
	// Render
	return (
		<>
			<SEO title={sponsor.name} />
			<Section
				role="region"
				title={sponsor.name}
				ariaLabel={sponsor.name}
				className="horizontal-padding padding-top padding-bottom"
			>
				<p className="font-body text-lg mb-6">
					Hi, my name is Greta and I’m a producer and sound engineer
					here at Litmus Media. We love using RØDE because they make
					incredible podcasting gear. All our hosts are kitted out
					with Procaster microphones and RØDECaster Pros which
					provides a fully-integrated production console and the
					ability to bring in phone and video calls. Here's Christina
					Pap recording Modern Australian Underground with me in our
					studio - hope you enjoy listening as much as we enjoy making
					these for you!
				</p>
				<a
					href={sponsor.website}
					target="_blank"
					rel="noopener noreferrer"
					aria-label="Website"
					className="text-black font-body text-lg flex flex-row items-center mb-8"
				>
					Website{" "}
					<span className="pl-2">
						<BsArrowRight size={20} />
					</span>
				</a>
				<Image
					fluid={data.rode.childImageSharp.fluid}
					alt="Rode Microphones, Brand Logo"
					className="lg:w-1/2 md:w-full"
					objectFit="contain"
					objectPosition="50% 50%"
				/>
			</Section>
		</>
	);
};

/* Export
============================================================================= */

export default SponsorTemplate;
